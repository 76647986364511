<template>
  <div class="container-fluid">
    <PageTitle :no-add="true" />
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-md-12">
            <div class="wrap_info_preview">
              <div class="table-responsive">
                  <table class="table product-overview no-border">
                  <tbody>
                      <tr>
                      <td>
                          <div class="wrap_preview_prof">
                          <h3>Kantor</h3>
                          <p>{{led.mo_name.charAt(0).toUpperCase() + led.mo_name.slice(1)}}</p>
                          </div>
                      </td>
                      <td>
                          <div class="wrap_preview_prof">
                          <h3>Unit Kerja</h3>
                          <p>{{led.muk_description}}</p>
                          </div>
                      </td>
                      </tr>
                      <tr>
                      <td>
                          <div class="wrap_preview_prof">
                          <h3>Nama Risiko</h3>
                          <p>{{led.mr_name}}</p>
                          </div>
                      </td>
                      <td>
                          <div class="wrap_preview_prof">
                              <h3>Nilai Kerugian</h3>
                              <p>{{led.ml_nilai_kerugian.replace('$', 'Rp. ').replace('.00', '')}}</p>
                          </div>
                      </td>
                      </tr>
                      <tr>
                      <td>
                          <div class="wrap_preview_prof">
                          <h3>Tanggal Kejadian</h3>
                          <p>{{led.ml_tgl_terjadi | moment('DD-MM-YYYY')}}</p>
                          </div>
                      </td>
                      <td>
                          <div class="wrap_preview_prof">
                          <h3>Hasil Evaluasi</h3>
                          <p>{{led.ml_hasil_evaluasi}}</p>
                          </div>
                      </td>
                      </tr>
                  </tbody>
                  </table>
              </div>
            </div>
          </div>
        </div>
          <ul class="nav nav-tabs my-3 customtab" role="tablist">
            <li class="nav-item"> <router-link :class="['nav-link', $route.meta.led == 'sebab' ? 'active' : '']" :to="{name: 'MrLedSebab', params: {ledSlug: $route.params.ledSlug}}"><span class="hidden-sm-up"></span> <span class="hidden-xs-down">Pustaka LED Sebab</span></router-link> </li>
            <li class="nav-item"> <router-link :class="['nav-link', $route.meta.led == 'dampak' ? 'active' : '']" :to="{name: 'MrLedDampak', params: {ledSlug: $route.params.ledSlug}}"><span class="hidden-sm-up"></span> <span class="hidden-xs-down">Pustaka LED Dampak</span></router-link> </li>
            <li class="nav-item"> <router-link :class="['nav-link', $route.meta.led == 'kendali' ? 'active' : '']" :to="{name: 'MrLedKendali', params: {ledSlug: $route.params.ledSlug}}"><span class="hidden-sm-up"></span> <span class="hidden-xs-down">Pustaka LED Pengendalian</span></router-link> </li>
        </ul>
      </div>
    </div>
    <template v-if="isList">
      <card-list 
        :filter.sync="filter"
        :dataListing.sync="data"
        :idKey="idKey"
        :fields="fields"
        
        :queryRoute=" {led_id:this.$route.params.ledSlug}"
        @getLed="getLed"
      > 
        <template #btnleft>
          <router-link
            :to="{name:$route.name, params:{pageSlug:'add-pustaka'}}" 
            class="btn btn-warning d-none d-lg-block m-l-15">
            <i class="icon-list"></i> Tambah Dari Pustaka
          </router-link>
          <router-link
            :to="{name:$route.name, params:{pageSlug:'add'}}" 
            class="btn btn-info d-none d-lg-block m-l-15">
            <i class="fa fa-plus-circle"></i> Tambah Data
          </router-link>
        </template>
        <!-- <template #filters>
          <b-col md=4>
            <VSelect 
              v-model="filter.kategori" 
              placeholder="-- Semua Kategori --"
              :options="mrKategori" 
              :reduce="v=>String(v.value)" 
              @input="doFilter"
            />
          </b-col>
        </template> -->
        <template #mrk_is_active="data">
          <LabelStatus :status="data.scope.item[statusKey]" />
        </template>
        <template #aksi="data">
          <div class="btn-toolbar">
            <button class="btn btn-danger" @click="doDelete(data.scope.index, data.scope.item)">
              <i class="ti-trash">

              </i>
            </button>
            <b-dropdown 
              no-flip 
              variant="info" 
              class="ml10" toggle-class="absolute-button"
            >
              <template #button-content>
                <i class="ti-exchange-vertical" v-b-tooltip.hover 
              title="Ganti Status"></i>
              </template>
              <b-dropdown-item 
                :class="{markedDropdown:data.scope.item[statusKey]==1}"
                @click="doChangeStatus(data.scope.index, data.scope.item, 1)"
              >
                Active
              </b-dropdown-item>
              <b-dropdown-item 
                :class="{markedDropdown:data.scope.item[statusKey]==2}"
                @click="doChangeStatus(data.scope.index, data.scope.item, 2)"
              >
                Inactive
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </template>
      </card-list>
    </template>
    <div 
      v-else 
      class="card"
    >
      <div class="card-body" v-if="$route.params.pageSlug !== 'add-pustaka'">
        <h5 class="card-title mb-3">{{isAdd?'Tambah':'Ubah'}} Data</h5>
        
        <ValidationObserver v-slot="{ handleSubmit }" ref="VForm2">
          <form @submit.prevent="handleSubmit(doAddRisiko)">
            <b-row>
              <b-col md=6 class="mb-2">
                <b-form-group>
                  <template #label>
                    Kategori <span class="text-danger mr5">*</span>
                  </template>
                  <VSelect 
                    v-model="row.mlk_category" 
                    placeholder="-- Pilih Satu --"
                    :options="mrKategori" 
                    :reduce="v=>v.value" 
                  />
                  <VValidate 
                    name="Master Kantor" 
                    v-model="row.mlk_category" 
                    :rules="{required: 1}" 
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>

              <b-col md=6 class="mb-2">
                <b-form-group>
                  <template #label>
                    Deskripsi <span class="text-danger mr5">*</span>
                  </template>
                  <b-input
                    type="text"
                    v-model="row.mlk_name"
                  />
                  <VValidate 
                    name="Nama Jabatan" 
                    v-model="row.mlk_name" 
                    :rules="'required'" 
                  />
                </b-form-group>
              </b-col>
            </b-row>
             <b-row>
              <b-col md=6 class="mb-2">
                <b-form-group>
                  <template #label>
                    Terkait <span class="text-danger mr5">*</span>
                  </template>
                  <VSelect 
                    v-model="row.mlk_terkait" 
                    placeholder="-- Pilih Satu --"
                    :options="terkait" 
                    :reduce="v=>v.value" 
                  />
                  <VValidate 
                    name="Master Kantor" 
                    v-model="row.mlk_terkait" 
                    :rules="{required: 1}" 
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                md=6
              >
                <b-form-group>
                  <template #label>
                    Status <span class="text-danger mr5">*</span>
                  </template>
                  <b-form-radio-group
                    id="radio-group-2"
                    v-model="row[statusKey]"
                    name="radio-group-2"
                  >
                    <b-form-radio value=1>Active</b-form-radio>
                    <b-form-radio value=2>Inactive</b-form-radio>
                  </b-form-radio-group>
                  <VValidate 
                    name="Status" 
                    v-model="row[statusKey]" 
                    :rules="{required: 1}" 
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <div class="row mt-4">
              <div class="col-md-2 offset-md-10">
                <button type="submit" class="btn btn-block btn-rounded  btn-info">Submit</button>
              </div>
            </div>
          </form>
        </ValidationObserver>

      </div>

      <div v-if="!isAdd && $route.params.pageSlug === 'add-pustaka'">
        <card-list 
          :filter.sync="filter"
          :dataListing.sync="mrRisikoKendali"
          :idKey="idKey"
          :fields="fieldsPustaka"

          :no-btn-mass="true"
          :no-multi-select="true"
        > 
          <template #aksi="data">
            <span v-if="data.scope.item.mrk_is_selected === 1">
              <span class="label label-purple mr-2">Telah Dipilih</span>
              <button class="btn btn-secondary" @click="doDeleteFromMaster(data.scope.item)">
                <i class="ti-trash"></i>
              </button>
            </span>

            <button v-else class="btn btn-secondary" @click="doAddRisikoFromMaster(data.scope.item)">
              <i class="ti-plus"></i>
              Pilih
            </button>

          </template>
        </card-list>
      </div>
    </div>
  </div>
</template>
<script>
import GlobalVue from '@libs/Global'
import PageTitle from '@view/include/PageTitle'
import CardList from '@/components/CardList'
import Gen from '@libs/Gen.js'

let $ = global.jQuery;
let _ = global._


export default {
   extends:GlobalVue
   ,
   components: {
      PageTitle,
      CardList
   },
   data(){
      return {
      idKey:'mlk_id',
      statusKey:'mlk_is_active',
      fields:[
          { key: "select", label: "" },
          { key:'number', label:'#' },
          { key:'mcr_description', label:'Kategori' },
          { key:'mrk_name', label:'Deskripsi' },
          { key:'mrk_is_active', label:'Status', is_custom:true },
          { key:'aksi', is_custom:true },
      ],
      fieldsPustaka: [
        { key: "number", label: '#' },
        { key: "cetegory", label: "Kategori" },
        { key: "name", label: "Description" },
        { key: "aksi", is_custom:true }
      ],
      mrPerspektif:[],
      mrUnitKerja: [],
      mrValidation: [],
      mrKategori: [],
      mrRisikoKendali: [],
      row: {},
      led: [],
      terkait: []
      }
   },
   mounted(){
    this.apiGet({query: {led_id:this.$route.params.ledSlug}})
    this.getLed()
  },
  watch:{
    $route(){
      this.apiGet({query: {led_id:this.$route.params.ledSlug}})
      this.getLed()
    }
  },
  methods: {
    getLed(){
        Gen.apiRest(
        "/get/"+this.modulePage,
        {
          params: {
            detailLed: this.$route.params.ledSlug
          }
        }
      ).then(res=>{
          this.led = res.data.led
        })
        .catch(err => console.log(err))
    },
    doAddRisiko() {
      const input = {
        mrk_risiko_id: this.led.ml_risiko_id,
        mrk_is_active: this.row.mlk_is_active,
        mrk_name: this.row.mlk_name,
        mrk_category: this.row.mlk_category,
        mrk_terkait: this.row.mlk_terkait,
        is_led: true,
        ml_id: this.led.ml_id
      }

      this.doSubmit(
        "/do/MrRisikoKendali",
        Object.assign(input, {
          type:'add'
        }),
        (type) => {
          if(type==='success'){
            this.$router.push({name:this.$route.name})
          }
        },
        "POST", "VForm2"
      )
    },
    doSubmit(action, input, callback=false, method="POST", IDFORM='VForm2'){
      this.$refs[IDFORM].validate().then(success => {
        if (!success) { return; }
        
        if(this.loadingOverlay) return
        this.loadingOverlay = true 

        var data = $.parseJSON(JSON.stringify(input));
        var btnSubmit = $(".btn-loading");
        var prevHtml = btnSubmit.html();

        if($(".infoHTML").length){
          $(".infoHTML").removeClass('alert alert-danger alert-warning');
          $(".infoHTML").html('');
        }

        btnSubmit.html();
        Gen.apiRest(action, {data: $.extend({params:this.params}, data)}, method).then((res)=>{
            this.loadingOverlay = false
            let resp = res.data

            btnSubmit.html(prevHtml);

            resp.statusType = 200;
            this.doSetAlertForm(resp);
            
            if(callback){
              callback('success', resp)
            }

            setTimeout(()=>{ this.message="" },15000);
        }).catch((err)=>{
            this.loadingOverlay = false
            btnSubmit.html(prevHtml);
            
            if(err){
                err.statusType = err.status;
                err.status = "error";
                err.message = err.response?.data?.message;
                err.messageError = err.message
            }

            this.doSetAlertForm(err);
            if(this.failReset)
                this.$nextTick(() => {
                    this.$refs.form.reset();
                    this.message=""
                });
                
            if(callback){
              callback('error', err)
            }
        })
      });
    },
    doSubmitCRUD(IDForm='VForm2'){
    return this.doSubmit(
        "/do/"+this.modulePage, 
        _.assign({type:this.isAdd?'add':'update'}, {id: (this.pageId||this.pageSlug), mrk_risiko_id: this.$route.params.risikoSlug}, _.clone(this.row)), 
        (type, resp)=>{
            if(type=='success'){
            this.$router.push({name:this.$route.name})
            }else{
            if(resp.response.status==422) {
                if((_.values(resp.response.data)[0][0]||"").indexOf('validation')>-1){
                var msg = ""
                $.each(Object.keys(resp.response.data),(i,v)=>{
                    msg = msg+" <br/>"+v+" : "+_.values(resp.response.data[v]);
                })
                return global.Swal.fire("Blocked With Validation!", msg)
                }else{
                return global.Swal.fire("Sorry, There is something wrong!", _.values(resp.response.data)[0][0])
                }
            }else if(resp.response.status==400){
                return global.Swal.fire({
                title: resp.response.data.message,
                icon: 'error',
                confirmButtonText: 'Ok'
                })
            }
            }
        },
        'POST', IDForm
    )
    },
    doAddRisikoFromMaster(data){
      const payload = {
        data:{
          ...data,
          idLed: +this.$route.params.ledSlug,
          type: "addFromMaster"
        }
      }
      this.loadingOverlay = true
      Gen.apiRest("/do/" + this.modulePage, payload, "POST")
        .then(res => {
          this.loadingOverlay = false
          global.Swal.fire({
            title: res.data.title,
            icon: res.data.status
          })

          this.apiGet({query: {led_id:this.$route.params.ledSlug}})
        })
    },
    doDeleteFromMaster(data){
      const payload = {
        data: {
          ...data,
          type: "deleteFromMaster"
        }
      }
      this.loadingOverlay = true
      Gen.apiRest("/do/" + this.modulePage, payload, "POST")
        .then(res => {
          this.loadingOverlay = false
          global.Swal.fire({
            title: res.data.title,
            icon: res.data.status
          })

          this.apiGet({query: {led_id:this.$route.params.ledSlug}})
        })
    }
  }
    
}
</script>